import ApiHandlerService from '../../services/api-handler';

export default class BalancesApi {
  static async get(packageId) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/balances-for-package-id`;
    const config = {};
    const query = {};

    if (packageId) {
      query.package_id = packageId;
    }

    // Adding timestamp to prevent browser for caching the result
    // The browser may cache the result of the url does not change
    query.ts = Date.now();

    return ApiHandlerService.fetch(url, config, query);
  }

  static async recordExposure() {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/balances/record-exposure`;
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async addManual(body) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/manual`;
    const config = {
      method: 'POST',
      body: JSON.stringify({ payload: { ...body } }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async addAddress(body) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/addresses/new`;
    const config = {
      method: 'POST',
      body: JSON.stringify({ payload: { ...body } }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async deleteAccount(body) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/manual/delete`;
    const config = {
      method: 'POST',
      body: JSON.stringify({ payload: { ...body } }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async deleteAddress(body) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/addresses/delete`;
    const config = {
      method: 'POST',
      body: JSON.stringify({ payload: { ...body } }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static async getInstruments(body) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/instruments`;
    const query = { exchange_code: body };
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config, query);
  }
}
